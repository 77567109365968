import "./doc.less"

const PrivacyStatement = (prop) => {
	return <div className="doc">
		<div className="doc-title">二级节点业务系统 隐私声明</div>
		<div className="doc-subtitle">隐私声明</div>
		<div className="doc-content">
			<div className="indentation">"二级节点业务系统"是由二级节点（以下简称"我们"）为您提供标识注册、标识查询、数据统计分析、系统管理等多种功能的应用。</div>
			<div className="indentation">我们非常重视您的个人信息和隐私保护，我们尽力保护您的个人信息安全，我们将会按照法律要求和业界成熟的安全标准，为您的个人信息提供相应的安全保护措施。本协议阐述了二级节点业务系统处理的数据以及二级节点业务系统处理数据的方式和目的；有助于您了解我们如何收集、整理、保存、加工和提供信息；在使用我们的服务前，请您仔细阅读。</div>
			<div className="indentation">本协议主要内容如下面所述：</div>
			<div className="indentation">一、我们不会收集法律法规所禁止采集的信息。我们将遵循“合法、正当、必要”原则依照本协议及我们与您的其他约定处理您的信息。</div>
			<div className="indentation">二、我们建立了严格的信息安全保障机制</div>
			<div className="indentation">三、我们将严格依法向您提供服务。除法律法规及本协议另有规定外，未经您授权，我们不会向任何第三方输出您的信息。</div>
			<div className="indentation bold">一、我们收集的信息及用途</div>
			<div className="indentation">在任何情况下，我们均将遵循“合法、正当、必要”的原则，依法收集您的信息。除依照国家法律法规公开的信息或本协议另有约定外，未经您授权不会收集您的个人信息。我们仅收集为您提供服务所必需的信息。我们不会收集您的宗教信仰、基因、指纹、血型、疾病和病史信息，也不会收集您的聊天、通话内容及您在社交媒体上的言论。</div>
			<div className="indentation">在遵守前述原则的前提下，以下情形我们需要收集您的信息，用以为您提供服务、提升我们的服务质量、保障您的帐户安全以及符合国家法律法规及监管规定。</div>
			<div className="indentation">1、注册和使用信息</div>
			<div className="indentation">为了确认您的身份，维护您的权益，在您使用二级节点业务系统服务时，您可能需提供您的企业相关信息、联系方式等，具体取决于您所选择的服务。在您使用二级节点业务系统服务过程中，我们可能需要您提供其他个人资料。</div>
			<div className="indentation">2、安全管理</div>
			<div className="indentation">为了向您提供安全稳定的服务，我们需要记录您使用的服务类别、方式及相关操作信息，例如所处网络环境、设备环境信息以及其他与二级节点业务系统服务相关的信息。</div>
			<div className="indentation">3、为了提供个性化服务及改进服务质量</div>
			<div className="indentation">为提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务，我们会收集您反馈的信息。</div>
			<div className="indentation">4、根据法律法规的要求，或为了维护公共安全、公共卫生、重大公共利益，或出于维护您或其他个人的生命、财产等重大权益的， 我们可能需要收集您的个人信息。</div>
			<div className="indentation">5、其他</div>
			<div className="indentation">除本协议约定外，在向您提供其他业务功能时，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。</div>
			<div className="indentation bold">二、我们如何存储和保护信息</div>
			<div
				className="indentation">1、我们在中国境内收集和产生的信息，将存储在中国境内（注：仅为本协议目的，本协议中所称中国境内不包含香港特别行政区、澳门特别行政区及台湾地区）。同时，我们采取了符合法律要求的技术措施及手段来保障您的信息在整理、保存、加工等各环节的安全。如涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，并通过签订保密协议等措施，要求境外机构对所获得的您的个人信息保密。我们仅在本协议所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。
			</div>
			<div
				className="indentation">2、我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。同时我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全体工作人员参加安全考试。
			</div>
			<div
				className="indentation">3、我们已制定信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责、应急处置策略和规程。若不幸发生信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况告知您，难以逐一告知用户时，我们会采取公告的方式进行告知。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。若您的合法权益受损，我们将依法承担相应的责任。
			</div>
			<div className="indentation">4、请您务必妥善保管好您的二级节点业务系统登录名及其他身份要素。您在使用二级节点业务系统服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现二级节点业务系统登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低损失。</div>
			<div className="indentation">5、在您终止使用二级节点业务系统服务后，我们会停止对您的信息的收集和使用，法律法规另有规定或我们与您另有约定的除外。</div>
			<div className="indentation bold">三、我们如何使用信息</div>
			<div className="indentation">1、我们将严格根据法律法规及监管规定以及您的授权，在如下情形下使用您的信息：</div>
			<div className="indentation">（1）实现本协议中“我们如何收集的信息及用途”所述目的。</div>
			<div className="indentation">（2）为了更好地为您提供服务，知晓您使用二级节点业务系统服务的状态，便于您及时了解信用评估结果的变化，您同意我们可以通过二级节点业务系统客户端相关页面及信息渠道向您展示服务信息。</div>
			<div className="indentation">（3）为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低损失。</div>
			<div className="indentation">（4）邀请您参与我们产品或服务有关的客户调研。</div>
			<div className="indentation">（5）我们会对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计产品、服务及运营活动等。我们可能根据前述信息向您提供营销活动通知、商业性电子信息或您可能感兴趣的广告，如您不希望接收此类信息，您可按照我们提示的方法选择退订。</div>
			<div className="indentation">（6）根据法律法规的要求，或为了维护公共安全、公共卫生、重大公共利益，或出于维护您或其他个人的生命、财产等重大权益的，我们可能需要使用你的个人信息。</div>
			<div className="indentation">2、除法律法规另有规定外，当您的信息被用于本协议未载明的其他用途时，我们会再次征求您的同意。</div>
			<div className="indentation bold">四、我们如何对外提供信息</div>
			<div className="indentation">（一）共享</div>
			<div className="indentation">我们承诺对您的信息进行保密。除本协议另有约定外，仅在下列情形下与信息使用者分享您必要的信息，同时我们将尽商业上的合理努力督促信息使用者在使用您的个人信息时遵守法律法规、履行保密义务及采取必要的安全措施：</div>
			<div className="indentation">1、在法律法规允许且不违背公序良俗的范围内，严格按照您的授权范围将您的信息提供给信息使用者。</div>
			<div className="indentation">2、如果您参与我们举办的投票、抽奖、竞赛或类似的市场活动，我们会将您提供的信息用于管理此类活动。若该活动是和第三方联合推广的，为了第三方能及时向您发放奖品或为您提供产品和服务，我们可能与其分享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式等。</div>
			<div className="indentation">（二）公开披露</div>
			<div className="indentation">除在市场运营活动中需公布中奖活动名单的情况下会脱敏展示中奖者手机号或其他联系方式外，原则上我们不会公开披露您的信息。如确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。</div>
			<div className="indentation">（三）委托处理</div>
			<div className="indentation">为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司代表我们来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托公司不再保存您的个人信息。</div>
			<div className="indentation">（四）为了保护您的信息安全，我们可能会选择具备身份验证能力的第三方验证您的身份。但我们会通过书面协议、现场审计等方式要求该等第三方遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。</div>
			<div className="indentation">（五）根据法律法规的要求，或为了维护公共安全、公共卫生、重大公共利益，或出于维护您或其他个人的生命、财产等重大权益的， 我们可能需要披露你的个人信息。</div>
			<div className="indentation bold">五、您如何访问和管理自己的个人信息</div>
			<div className="indentation">在您使用二级节点业务系统服务期间，为了您可以更加便捷地管理您的个人信息，同时保障您关闭帐户的权利，我们在产品设计中为您提供了相应的操作设置，您可以参考下面的指引进行操作。</div>
			<div className="indentation">1、更新您的个人及企业资料</div>
			<div className="indentation">您可通过二级节点业务系统来更新或完善您的个人及企业资料。</div>
			<div className="indentation">2、更正信息</div>
			<div className="indentation">您可以通过二级节点业务系统的注册，了解您的履约情况，以及是否有需要及时履约的情形，以便维护您的信用。除本协议另有约定外，未经您的授权，我们不会向任何第三方披露您的违约记录。违约记录来源于合作机构，我们仅按照该机构提供的信息如实展示在您本人的二级节点业务系统里，并据此提供服务。如您认为我们展示的违约记录有误，您可反馈给我们，我们将协助您向该机构进行核实；同时您也可自行联系该机构进行核实。</div>
			<div className="indentation">3、如您发现我们采集、使用信息的行为，违反了法律、行政法规规定或违反了与您的约定，您可联系在线客服或客服电话联系我们，要求删除该违反行为下采集的您的信息。</div>
			<div className="indentation bold">六、对第三方责任的声明</div>
			<div
				className="indentation">请您注意，您访问的第三方网站经营者等第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本协议的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
			</div>
			<div className="indentation bold">七、信息保护提醒</div>
			<div className="indentation">信息保护需要您和我们的共同努力。为保障信息安全，我们会根据信息类型及范围，结合我们对相应环境的风险识别及认知，视情况利用设备环境分析、手机短信校验码等方式进行验证。 同时建议您在向他人提供信息时，采取合理措施来避免信息泄露或不当使用风险，不要将密码提供给其他人，注意个人信息安全。</div>
			<div className="indentation bold">八、本协议的适用和更新</div>
			<div className="indentation">1、二级节点业务系统的所有服务均适用本协议。您可以在二级节点业务系统部分页面查看本协议。我们鼓励您在使用二级节点业务系统服务时多查阅本协议。</div>
			<div className="indentation">2、发生下列重大变化情形时，我们会适时对本协议进行更新：</div>
			<div className="indentation">（1）收集、存储、使用信息的范围、目的、规则发生变化；</div>
			<div className="indentation">（2）对外提供信息的对象、范围、目的发生变化；</div>
			<div className="indentation">（3）您访问和管理信息的方式发生重大变化；</div>
			<div className="indentation">（4）数据安全能力、信息安全风险发生重大变化；</div>
			<div className="indentation">（5）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生重大变化；</div>
			<div className="indentation">（6）其他可能对您的信息权益产生重大影响的变化。</div>
			<div className="indentation">3、为满足您专业化、多样化的需求，我们的服务可能会升级，同时因法律法规变化或我们收集信息的渠道、范围或用途等发生变化，我们需要对本协议进行修订。欢迎您了解本协议。如您在本协议更新生效后继续使用我们的服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。</div>
		</div>
	</div>
}
export default PrivacyStatement
