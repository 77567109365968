import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Table as AntTable, Pagination, Alert } from 'antd';
import SearchBar from './SearchBar'
import Export from '../export'

const Table = (props,tableRef) => {
    useImperativeHandle(tableRef, () => ({
        getSelectedRows: () => {
          return selectedRows
        },
        clearSelectedRows: () => {
            setSelectedRows([])
            setSelectedRowKeys([])
        },
        handleSubmit: () => {
            handleSubmit()
        }
    }))

    const searchBarRef = useRef(null);

    const {
        style,
        title, // 表格名称，显示在表格右上方
        columns, // 表格列配置
        rowKey,
        dataSource, // 表格数据源
        loading, // 读取数据的状态
        defaultFields, // 默认筛选字段，外部传入
        fields, // 筛选字段
        total, // 数据总数
        extraBtn, // 额外的按钮，如导出
        miscellaneousSlot,
        rowSelection, // 表格勾选功能
        rowSelectionButtons, 
        onGetData, // 读取数据的方法
        exportUrl,// 导出数据的url
        notAutoLoad // 第一次不自动请求数据
    } = props

    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    useEffect(() => {
        if(!notAutoLoad){
            handleSubmit()
        }
    }, [])

    const getRowSelectionOptions = () => {
        if(rowSelection) {
            return {
                selectedRowKeys,
                onChange: (keys, rows) => {
                    let keysTemp = [...selectedRowKeys]
                    let rowsTemp = [...selectedRows]
                    keys.map((key, index) => {
                        if(!selectedRowKeys.includes(key)) {
                            keysTemp.push(key)
                            rowsTemp.push(rows[index])
                            setSelectedRowKeys([...keysTemp])
                            setSelectedRows([ ...rowsTemp])
                        }
                    })
                },
                onSelect: (record, selected) => {
                    if(!selected) {
                        let selectedRowKeysTemp = [...selectedRowKeys]
                        let selectedRowsTemp = [...selectedRows]
                        let romoveIndex = selectedRowKeysTemp.findIndex((key) => key === record.key)
                        if(romoveIndex !== -1) {
                            selectedRowKeysTemp.splice(romoveIndex, 1)
                            selectedRowsTemp.splice(romoveIndex, 1)
                            setSelectedRowKeys([...selectedRowKeysTemp])
                            setSelectedRows([...selectedRowsTemp])
                        }
                    }
                },
                onSelectAll: (selected, rows, changeRows) => {
                    if(!selected) {
                        let selectedRowKeysTemp = [...selectedRowKeys]
                        let selectedRowsTemp = [...selectedRows]
                        changeRows.map((changeRow) => {
                            let romoveIndex = selectedRowKeysTemp.findIndex((key) => key === changeRow.key)
                            if(romoveIndex !== -1) {
                                selectedRowKeysTemp.splice(romoveIndex, 1)
                                selectedRowsTemp.splice(romoveIndex, 1)
                                setSelectedRowKeys([...selectedRowKeysTemp])
                                setSelectedRows([...selectedRowsTemp])
                            }
                        })
                    }
                },
                getCheckboxProps: (record) => ({
                    name: record.name,
                    defaultChecked: selectedRowKeys.includes(`${record.key}`),
                })
            }
        }
        return {}
    }

    const handleSubmit = () => {
        if (!onGetData) return
        if(searchBarRef && searchBarRef.current){
        onGetData({...defaultFields, ...searchBarRef.current.getFieldsValue(), pageNo: 1, pageSize: pageSize})
        } else {
        onGetData({...defaultFields, pageNo: 1, pageSize: pageSize})
        }
        setPageNo(1)
    }

    const paginationOnChange = (current, size) => {
        if (!onGetData) return
        setPageNo(current)
        setPageSize(size)
        if(searchBarRef && searchBarRef.current){
        onGetData({...defaultFields, ...searchBarRef.current.getFieldsValue(), pageNo: current, pageSize: size})
        } else {
        onGetData({...defaultFields, pageNo: current, pageSize: size})
        }
    }

    const getAntTableProps = () => {
        let props = {
            columns,
            dataSource,
            loading,
            rowKey
        }
        return rowSelection ? {...props, rowSelection: getRowSelectionOptions()} : {...props}
    }
    
    return (
        <div style={{background: '#fff', ...style}}>
            { (fields && fields.length > 0) && <SearchBar fields={fields} extraBtn={extraBtn} exportUrl={exportUrl} handleSubmit={handleSubmit} ref={searchBarRef}></SearchBar> }
            { miscellaneousSlot && miscellaneousSlot.length > 0 && miscellaneousSlot}
            
            <div style={{borderTop: '1px solid #F2F4F7'}}>
                {selectedRowKeys.length > 0 && rowSelectionButtons}
                {selectedRowKeys.length > 0 && <Alert message={<div>已选择{selectedRowKeys.length}项数据 <a onClick={() => {setSelectedRowKeys([])}}>清空</a></div>} type="info" showIcon />}
                <div style={{padding: '10px 40px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0'}}>
                        {title ? <div style={{fontSize: '20px', fontWeight: '400',height:'40px'}}>{title}</div> : <div style={{height:'40px',fontSize: '20px', color: '#fff'}}>Table</div>}
                        <div>
                            {exportUrl && searchBarRef.current ? <Export style={{ marginLeft: '8px' }} url={exportUrl} params={{...searchBarRef.current.getFieldsValue()}} /> : null}
                            {extraBtn}
                        </div>
                    </div>
                    <AntTable {...getAntTableProps()} pagination={false}></AntTable>
                </div>
                {total > 0 && <Pagination style={{textAlign: 'center',height:'76px',lineHeight:'66px'}} current={pageNo} pageSize={pageSize} total={total} onChange={paginationOnChange} onShowSizeChange={paginationOnChange} showSizeChanger showTotal={(total, range) => `当前展示${range[0]}-${range[1]}项数据, 共有${total} 条数据`}></Pagination>}
            </div>
            
        </div>
    )
}
export default forwardRef(Table)