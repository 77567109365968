const Icon = (props) => {
    const { collapsed } = props
    const imgStyle = {
        width: '50px',
        height: '50px'
    }
    const textStyle = {
        marginLeft: '8px', 
        fontSize: '18px',
        fontWeight: 600
    }
    return (
        <div className={ collapsed ? "app-icon-collapsed" : "app-icon"}>
            { collapsed ? <img style={imgStyle} src={"/img/logo.png"} alt="塑料行情报价" /> : 
                <>
                    <img style={imgStyle} src={"/img/logo.png"} alt="塑料行情报价" />
                    <span style={textStyle}>塑料行情报价</span> 
                </>
            }
        </div>
        
    )
}
export default Icon

