import React, { useContext } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom';
import AppIcon from './icon'
import { GlobalContext } from '@/store'
import {
    LineChartOutlined
  } from '@ant-design/icons';

const SideBar = (prop) => {
    const { app } = useContext(GlobalContext)
    const { collapsed } = app
    return (
        <div className={ collapsed ? 'app-side-bar-collapsed' : 'app-side-bar'}>
            <AppIcon collapsed={collapsed} />
            <Menu
                className="app-menu"
                theme="light"
                mode="inline"
                inlineCollapsed={collapsed}
                defaultSelectedKeys={["1"]}
            >
                <Menu.Item key={1} icon={<LineChartOutlined />}>
                    <Link to={'/app/price'}>价格管理</Link>
                </Menu.Item>
            </Menu> 
        </div>
        
    )
}
export default SideBar

