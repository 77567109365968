import {
    useReducer
} from 'react';

const initState = {
    lastReq: undefined,
    productList: [],
    productListTotal: 0,
    pageNo:1,
    pageSize:10,
}

function Reducer() {
    const [price, priceDispatch] = useReducer((state, action) => {

        switch (action.type) {
            case 'SET_PRODUCT_LIST':
                const {
                    results, totalRecord, lastReq,pageNo,pageSize
                } = action.payload
                return Object.assign({}, state, {
                    ...state,
                    pageNo,
                    pageSize,
                    productList: results,
                    productListTotal: totalRecord,
                    lastReq: lastReq
                });
            default:
                return {
                    ...state
                }
        }
    }, initState)
    return {
        price,
        priceDispatch
    }
}

export default Reducer