import React, {useState, useEffect, useContext} from "react"
import { Button, Col, Space, Table } from 'antd'
import { HashRouter as Router, Route, Switch, useHistory} from 'react-router-dom'
import { getCountOffer } from '@/api/price'
import { GlobalContext } from '@/store'
import dayjs from 'dayjs'

const Import = () => {
  const IMPORT_RESULT = JSON.parse(sessionStorage.getItem('IMPORT_RESULT'))
  const history = useHistory()
  const context = useContext(GlobalContext)
  const { user } = context
  const { userInfo } = user
  const [totalQuotation, setTotalQuotation] = useState(0)
  const [maxQuotation, setMaxQuotation] = useState(100);

  useEffect(() => {
    if (userInfo) {
      setMaxQuotation(userInfo.authenticationStatus === 1 ? 200 : 100)
    }
  }, [user.userInfo]);

  useEffect( () => {
    inquireTotalQuotation()
  }, [])
  const inquireTotalQuotation = async () => {
    const total = await getCountOffer()
    setTotalQuotation(total)
  }
  const columns = [
    {
      title: '品名',
      dataIndex: 'productName',
      key: 'productName',
      align: 'center'
    },
    {
      title: '厂家',
      dataIndex: 'factoryName',
      key: 'factoryName',
      align: 'center'
    },
    {
      title: '牌号',
      dataIndex: 'brandName',
      key: 'brandName',
      align: 'center'
    },
    {
      title: '报价（元/吨）',
      dataIndex: 'price',
      key: 'price',
      align: 'center'
    },
    {
      title: '数量（吨）',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center'
    },
    {
      title: '报价时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      align: 'center',
      render: (text) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>
    }
  ]

  const getColStyle = {
    border: '1px solid #E5E6EB',
    borderRadius: 4,
    padding: 20,
  }
  return (
    <>
      <div style={{ background: '#ffffff', padding: 20 }}>
        <div>
          <Space>
            <Button type='primary' onClick={() => {history.push('/app/price')}}>返回首页</Button>
          </Space>
        </div>
        <div style={{ marginTop: 20, background: totalQuotation >= maxQuotation ? '#FFEFE8' : '#F0F8FF', padding: '14px 16px', display: 'flex', alignItems: 'center' }}>
          { totalQuotation >= maxQuotation ?
            <img style={{ width: 20, height: 20, marginRight: 10 }} src="/img/title-icon-2.png" alt="" />
            :
            <img style={{ width: 20, height: 20, marginRight: 10 }} src="/img/title-icon-1.png" alt="" />
          }
          { totalQuotation >= maxQuotation ?
            <span >平台为每家公司免费提供100条报价，注意总报价数超过上限后不可再报价，您可以前往首页删减报价数量后再提交</span>
            :
            <span style={{ fontSize: 14, color: '#1890FF', lineHeight: '16px' }}>
              平台为每家公司免费提供100条报价，目前该公司已有报价{totalQuotation}条，还可报价{maxQuotation - totalQuotation}条 (当提交统一【品名/厂家/牌号】的数据记为重复数据，系统自动更新该条牌号价格及数量)
            </span>
          }

        </div>
        <Col style={{ marginTop: 20, fontSize: 16, color: '#1D2129 ', fontWeight: 500}}>
          批量导入结果：
        </Col>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
          <Col span={5} style={getColStyle}>
            <div style={{ color: '#4E5969', fontSize: 16 }}>
              共收到批量报价
            </div>
            <div style={{ marginTop: 8, fontSize: 32, color: ' #1890FF' }}>
              {IMPORT_RESULT.totalCount}
              <span style={{ fontSize: 14, color: '#4E5969' }}>条</span>
            </div>
          </Col>
          <Col span={5} style={getColStyle}>
            <div style={{ color: '#4E5969', fontSize: 16 }}>
              导入成功
            </div>
            <div style={{ marginTop: 8, fontSize: 32, color: ' #1890FF' }}>
            {IMPORT_RESULT.addCount}
              <span style={{ fontSize: 14, color: '#4E5969' }}>条</span>
            </div>
          </Col>
          <Col span={5} style={getColStyle}>
            <div style={{ color: '#4E5969', fontSize: 16 }}>
              导入失败
            </div>
            <div style={{ marginTop: 8, fontSize: 32, color: '#F93920' }}>
              {IMPORT_RESULT.failCount}
              <span style={{ fontSize: 14, color: '#4E5969' }}>条</span>
            </div>
          </Col>
          <Col span={5} style={getColStyle}>
            <div style={{ color: '#4E5969', fontSize: 16 }}>
              更新报价
            </div>
            <div style={{ marginTop: 8, fontSize: 32, color: ' #FC8800' }}>
              {IMPORT_RESULT.updateCount}
              <span style={{ fontSize: 14, color: '#4E5969' }}>条</span>
            </div>
          </Col>
        </div>

        <Col style={{ marginTop: 20, padding: 10, fontSize: 16, color: '#1D2129', textAlign: 'center', fontWeight: 500, border: '1px solid #E5E6EB', marginBottom: 10 }}>导入失败表单</Col>
        <Table columns={columns} dataSource={IMPORT_RESULT.failList} pagination={false}></Table>
      </div>
    </>
  )
}
export default Import
