import {useReducer} from 'react';

const initState = {
    collapsed: false
}
function Reducer (){
    const [app, appDispatch] = useReducer((state, action)=>{
        switch(action.type){
            case 'CHANGE_COLLAPSED':
                state.collapsed = action.payload
                break;
            default:
                break;  
        }
        return { ...state }

    }, initState)
    return { app, appDispatch }
}

export default Reducer