import http from '@/http'

// 获取报价列表
export const getOfferList = (params) => {
    return http({
        url: '/api/offer/page',
        method: 'get',
        params
    })
}

// 一键更新报价
export const renewOfferList = (params) => {
    return http({
        url: '/api/offer/renew',
        method: 'get',
        params
    })
}

// 更新报价信息
export const updateOfferList = (data) => {
    return http({
        url: '/api/offer/updateItem',
        method: 'post',
        data
    })
}

// 获取市场报价
export const  getSummaryDetail= (params) => {
    return http({
        url: '/api/offer/summaryDetail',
        method: 'get',
        params
    })
}

// 删除报价
export const deleteOffer = (params) => {
    return http({
        url: '/api/offer/delete',
        method: 'post',
        params
    })
}

// 新增报价单
export const addOffer = (data) => {
    return http({
        url: '/api/offer/add',
        method: 'post',
        data
    })
}

// 查询品名
export const getProductList = (params) => {
    return http({
        url: '/api/offer/brand/productList',
        method: 'get',
        params
    })
}

// 查询厂家
export const getFactoryList = (params) => {
    return http({
        url: '/api/offer/brand/factoryList',
        method: 'get',
        params
    })
}

// 查询牌号
export const getBrandList = (params) => {
    return http({
        url: '/api/offer/brand/list',
        method: 'get',
        params
    })
}

// 获取二维码
export const getQrCode = (params) => {
    return http({
        url: '/api/offer/qrcode',
        method: 'get',
        params
    })
}

// 查询总数
export const getCountOffer = (params) => {
    return http({
        url: `/api/offer/countOffer`,
        method: 'GET',
        params
    })
}