import React from 'react';
import AppReducer from './reducer/app'
import UserReducer from './reducer/user'
import PriceReducer from './reducer/price'

export const GlobalContext = React.createContext()

function Store(props){
    const { app, appDispatch } = AppReducer()
    const { user, userDispatch } = UserReducer()
    const { price, priceDispatch } = PriceReducer()
    
    const ProviderValue = {
        app,
        appDispatch,
        user,
        userDispatch,
        price,
        priceDispatch
    }

    return (
        <GlobalContext.Provider value={ProviderValue}>
            {props.children}
        </GlobalContext.Provider>
    )
}
export default Store