import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { Avatar, Menu,Dropdown } from 'antd'
import {  CaretDownOutlined, CaretUpOutlined, } from '@ant-design/icons'
import { logout } from '@/api/login'
import UserInfoModal from '@/page/modal/userInfo'

const UserInfo = props => {
    const { userInfo } = props
    const [userInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const history = useHistory()
    const [dropDown, setDropDown] = React.useState(false)
    const onLogout = async () => {
        await logout()
        sessionStorage.clear()
        history.replace('/login')
    }
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" onClick={() => {
                    setUserInfoModalVisible(true)
                }}>
                企业信息
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" onClick={() => {
                    onLogout()
                }}>
                退出系统
                </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <>
            <div style={{width: '150px', cursor: 'pointer'}} onMouseOver={() => {setDropDown(true)}} onMouseLeave={() => {setDropDown(false)}}>
                <Dropdown  overlay={menu} placement="bottomCenter">
                    <div style={{ display: 'flex', width: '100px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Avatar src={userInfo?.avatarUrl} />
                            <span style={{ maxWidth: '80px', padding: '0 10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{userInfo?.nickName}</span>
                            {dropDown?<CaretUpOutlined style={{color: "#8492A6"}} /> : <CaretDownOutlined style={{color: "#8492A6"}} />}
                        </div>
                    </div>
                </Dropdown>
            </div>
            <UserInfoModal visible={userInfoModalVisible} setVisible={setUserInfoModalVisible} />
        </>
    )
}
export default UserInfo