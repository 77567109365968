import React from 'react'
import { useContext } from 'react'
import { GlobalContext } from '@/store'
import { Modal, Form, Input, Button, Space, message } from 'antd'
import { getUserInfo, updateUserInfo } from '@/api/user'
const UserInfoModal = (props) => {
    const { visible, setVisible } = props
    const context = useContext(GlobalContext)
    const { user, userDispatch } = context
    const { userInfo } = user
    const [form] = Form.useForm();
    const onCancel = () => {
        setVisible(false)
        form.resetFields()
    }
    const onFinish = async (values) => {
        await updateUserInfo({...userInfo, ...values})
        let res = await getUserInfo()
        userDispatch({ type: 'SET_USER_INFO', payload: res})
        message.success('修改成功')
        onCancel()
    }
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      }
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      }
    return <>
        <Modal visible={visible} title="企业信息" onCancel={onCancel} footer={null}>
            <div style={{marginBottom: "20px"}}>提示：请先完善您的企业信息再进行报价！否则报价无法提交！</div>
            <Form {...layout} form={form} initialValues={{ ...userInfo }} onFinish={onFinish}>
                <Form.Item label="公司名称" name="companyName" rules={[{ required: true, message: '请输入公司名称' }]} maxLength={50}> 
                    <Input />
                </Form.Item>
                <Form.Item label="联系人" name="contact" rules={[{ required: true, message: '请输入联系人' }]} maxLength={50}> 
                    <Input />
                </Form.Item>
                <Form.Item label="联系电话" name="contactMobile" rules={[{ required: true, message: '请输入联系电话' }, {pattern: /^1\d{10}$/, message: "联系电话格式错误"}]} maxLength={15}> 
                    <Input />
                </Form.Item>
                <Form.Item label="联系地址" name="contactAddress" rules={[{ required: true, message: '请输入联系地址' }]} maxLength={50}> 
                    <Input />
                </Form.Item>
                <Form.Item label="备注" name="remarks" maxLength={50}> 
                    <Input />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button htmlType="button" onClick={onCancel}>
                            取消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    </>
}
export default UserInfoModal