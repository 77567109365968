import { useState } from 'react'
import { Link } from 'react-router-dom';
import {message} from "antd"
import { wxLogin, login } from '@/api/login'
import "./login.less"

const Login = (prop) => {
    const [isHover, setIsHover] = useState(false)
    const [isAgree, setIsAgree] = useState(false)
    const onLogin = async () => {
        if (!isAgree) return message.info("请同意《用户协议》及《隐私协议》")
        if (process.env.NODE_ENV === "development") {
            await login({userId: "285b7a9500001134"})
            window.location.href = "/"
        } else {
            window.location.href = await wxLogin({to: "/"})
        }
    }

		const bodyStyle = {background: "url(\"/img/login-bg.png\")"}

    return <div className="container">
        <div className="header">
            <div>
                <img src="/img/logo.png"></img>
                <div className="line"></div>
                <span className="title">欢迎登录</span>
            </div>
            <div>
                <span className="server-label">服务热线:</span>
                <span className="server-value">400-112-4366</span>
            </div>
        </div>
        <div className="body" style={bodyStyle}>
            <div className="login-box">
                <div className="login-box-title">账号登录</div>
                <div className="login-box-content">
                    {
                        isHover ?
                        <img className="wechat" src="/img/weixin-hover.png" onMouseLeave={() => {setIsHover(false)}} onClick={() => {onLogin()}}></img> :
                        <img className="wechat" src="/img/weixin.png" onMouseOver={() => {setIsHover(true)}}></img>}
                    <span className="wechat-text">微信扫码快捷登录</span>
                </div>
                <div className="agreement fixed">
                    <input type="radio" className="radio" checked={isAgree} onClick={() => setIsAgree(!isAgree)} />
                    <span>登录即代表同意</span>
                    <Link className="doc-link" to="/userAgreement">《用户协议》</Link>
                    <span>及</span>
                    <Link className="doc-link" to="/privacyStatement">《隐私协议》</Link>
                </div>
            </div>
        </div>
        <div className="footer">
            © 2010 - 2019 版权所有, all rights reserved  东莞市盟大塑化科技有限公司  服务热线：400-115-2868   粤ICP备10094747号
        </div>
        {/* <button onClick={onLogin}>点击进行微信登录</button> */}
    </div>
}
export default Login
