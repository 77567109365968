import http from '@/http'

export const wxLogin = (params) => {
    return http({
        url: '/api/oauth2/qrConnect',
        method: 'get',
        params
    })
}

export const login = (params) => {
    return http({
        url: '/api/user/login2',
        method: 'get',
        params
    })
}

export const logout = () => {
    return http({
        url: '/api/user/logout',
        method: 'get'
    })
}