import React, { useState } from 'react'
import { Button, message } from 'antd';
import http from '../../http'

const Export = (props) => {
    const {url, params, type, style} = props
    const [loading, setLoading] = useState(false)
    
    const handleExport = () => {
        setLoading(true)
        http({
            url: url,
            params: params
        }).then((res) => {
            if(res.url) {
                let a = document.createElement('a')
                a.target = '_blank';
                a.href = res.url
                a.click()
            } else {
                message.info('已生成导出任务')
            }
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }

    return (
        <Button style={style} type={type} loading={loading} onClick={() => handleExport()}>导出</Button>
    );
}
Export.defaultProps = {type: 'primary', style: {}, params: {}}
export default Export