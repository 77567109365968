import { Form, Input, InputNumber, Button, Space, Statistic, Select, Divider, message, Modal, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getProductList, getFactoryList, getBrandList, getSummaryDetail, addOffer, getQrCode } from '@/api/price'
import { useState, useEffect } from 'react';

const { Option } = Select;

const AddOfferDlg = (props) => {

    const [form] = Form.useForm()


    // const [options, setOptions] = useState([]);
    const [productList, setProductList] = useState([])  // 品名列表
    const [factoryList, setFactoryList] = useState([])  // 厂家列表
    const [brandList, setBrandList] = useState([])      // 牌号列表

    const [product_keyword, setProduct_keyword] = useState('') // 品名的搜索关键字
    const [product_pageNo, setProduct_pageNo] = useState(1) // 品名的页码
    const [product_totalRecord, setProduct_totalRecord] = useState(10) // 品名的数量
    const [product_selected, setProduct_selected] = useState('') // 选择的品名

    const [factory_keyword, setFactory_keyword] = useState('') // 厂家的搜索关键字
    const [factory_pageNo, setFactory_pageNo] = useState(1) // 厂家的页码
    const [factory_totalRecord, setFactory_totalRecord] = useState(20) // 厂家的数量
    const [factory_selected, setFactory_selected] = useState('') // 选择的厂家

    const [brand_keyword, setBrand_keyword] = useState('') // 牌号的搜索关键字
    const [brand_pageNo, setBrand_pageNo] = useState(1) // 牌号的页码
    const [brand_totalRecord, setBrand_totalRecord] = useState(10) // 牌号的数量

    const [loading, setLoading] = useState(false)   // 记载更多的

    const [currentTotal, setCurrentTotal] = useState(1)

    // 打开弹窗时，默认加载品名
    useEffect(async () => {
        if (props.type === 'add') {
            let { results, totalRecord } = await getProductList({ pageNo: 1, pageSize: 10 })
            setProductList(results)
            setProduct_totalRecord(totalRecord)
        }
        // else if (props.type === 'edit') { // 编辑回显
        //     let { productName, factoryName, brandName, price, quantity } = props

        //     let formValue = form.getFieldValue('offerList')[0]
        //     formValue.productName = productName
        //     form.setFieldsValue({ 'offerList': [...form.getFieldValue('offerList')] })
        // }
    }, [])

    // 打开弹窗时，默认加载品名
    useEffect(async () => {
        if (props.type === 'edit') { // 编辑回显
            let { productName, factoryName, brandName, price, quantity } = props.currentOffer

            let formValue = form.getFieldValue('offerList')[0]
            formValue.productName = productName
            formValue.factoryName = factoryName
            formValue.brandNumber = brandName
            formValue.price = price
            formValue.quantity = quantity

            form.setFieldsValue({ 'offerList': [...form.getFieldValue('offerList')] })
        }
    }, [form])

    // 生成报价
    const onFinish = async ({ offerList }) => {
        for (let i in offerList) {
            offerList[i].brandName = offerList[i].brandNumber
        }
        let id = await addOffer({ list: offerList })
        if(id) {
            message.success('生成报价成功')
            props.closeDlg()
            let imgUrl = await getQrCode({id: id})
            if(imgUrl) {
                Modal.success({
                    content: (
                      <div style={{textAlign: 'center'}}>
                        <img width={300} height={300} src={imgUrl}/>
                      </div>
                    ),
                    icon: null,
                    closable: true,
                    okText: '关闭'
                  });
            }
        }
    };

    // 选择品类后，根据品类搜索厂家
    const onSelectProduct = async (productName) => {
        let { results, totalRecord } = await getFactoryList({ productName })
        setFactoryList(results)
        setFactory_totalRecord(totalRecord)
        setProduct_selected(productName)
    }

    // 品名分页加载
    const loadMoreProduct = async () => {
        let newNo = product_pageNo + 1
        let { results, totalRecord } = await getProductList({ pageNo: newNo, pageSize: 10, productName: product_keyword })
        setProductList(productList.concat(results))
        setProduct_totalRecord(totalRecord)
        setProduct_pageNo(newNo)
    }

    // 搜索品名
    const onSearchProduct = async (e) => {
        let { results, totalRecord } = await getProductList({ productName: e.target.value, pageNo: 1, pageSize: 10 })
        setProductList(results)
        setProduct_totalRecord(totalRecord)
        setProduct_pageNo(1)
        setProduct_keyword(e.target.value)
    }


    // 选择厂家后，根据厂家搜索牌号
    const onSelectFactory = async (factoryName) => {
        let { results } = await getBrandList({ factoryName })
        setBrandList(results)
        setFactory_selected(factoryName)
    }

    // 厂家分页加载
    const loadMoreFactory = async () => {
        let newNo = factory_pageNo + 1
        let { results, totalRecord } = await getFactoryList({ productName: product_selected, factoryName: factory_keyword, pageNo: newNo, pageSize: 20 })
        setFactoryList(factoryList.concat(results))
        setFactory_totalRecord(totalRecord)
        setFactory_pageNo(newNo)
    }

    // 搜索厂家
    const onSearchFactory = async (e) => {
        let { results, totalRecord } = await getFactoryList({ productName: product_selected, factoryName: e.target.value, pageNo: 1, pageSize: 20 })
        setFactoryList(results)
        setFactory_totalRecord(totalRecord)
        setFactory_pageNo(1)
        setFactory_keyword(e.target.value)
    }

    // 选择牌号后，搜索市场价
    const onSelectBrand = (key) => async (value) => {
        let formValue = form.getFieldValue('offerList')[key]
        let { marketPrice, marketPriceFloats } = await getSummaryDetail({ productName: formValue.productName, factoryName: formValue.factoryName, brandName: formValue.brandNumber })
        formValue.marketPrice = marketPrice
        formValue.marketPriceFloats = marketPriceFloats === null ? null : (marketPriceFloats * 100).toFixed(2)

        form.setFieldsValue({ 'offerList': [...form.getFieldValue('offerList')] })

    }

    // 牌号分页加载
    const loadMoreBrand = async () => {
        let newNo = brand_pageNo + 1
        let { results, totalRecord } = await getBrandList({ factoryName: factory_selected, brandNumber: brand_keyword, pageNo: newNo, pageSize: 20 })
        setBrandList(brandList.concat(results))
        setBrand_totalRecord(totalRecord)
        setBrand_pageNo(newNo)
    }

    // 搜索牌号
    const onSearchBrand = async (e) => {
        let { results, totalRecord } = await getBrandList({ factoryName: factory_selected, brandNumber: e.target.value, pageNo: 1, pageSize: 20 })
        setBrandList(results)
        setBrand_totalRecord(totalRecord)
        setBrand_pageNo(1)
        setBrand_keyword(e.target.value)
    }

    const setValuesChange =(item, allItem) => {
        setCurrentTotal(allItem.offerList.length)
    }

    return (
        <Form form={form} name="dynamic_form_item" onFinish={onFinish} initialValues={{ 'offerList': [{}] }} onValuesChange={setValuesChange}>
            {props.type != 'edit' &&
                <Form.Item>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',background: '#F0F8FF',borderRadius: '2px 2px 2px 2px', padding: '13px 40px'}}>
                        <div style={{flex: '0 0 auto', color: '#000000 ', fontSize: '14px'}}>
                            <Space size={40}>
                                <span>已报价<span style={{color: '#1890FF'}}>{props.totalQuotation}</span>条</span>
                                <span>当前待报价<span style={{color: '#1890FF'}}>{currentTotal}</span>条</span>
                                <span>还可报价<span style={{color: '#F93920'}}>{100 - props.totalQuotation - currentTotal}</span>条</span>
                            </Space>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', color: '#666666', fontSize: '14px'}}>
                            注意：平台免费提供 100 条总报价，请控制好报价数量，否则影响提交
                        </div>
                    </div>
                </Form.Item>
            }
            <Form.List name="offerList" style={{ display: 'flex' }}  >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    label={'品名：'}
                                    name={[name, 'productName']}
                                    fieldKey={[fieldKey, 'productName']}
                                    rules={[{ required: true, message: '请选择' }]}
                                >
                                    <Select
                                        style={{ width: '150px' }}
                                        onChange={onSelectProduct}
                                        disabled={props.type === 'edit'}
                                        dropdownRender={menu => (
                                            <div>
                                                <div style={{ padding: '4px 6px' }}>
                                                    <Input placeholder="搜索" onChange={onSearchProduct} allowClear />
                                                </div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, textAlign: 'center' }}>
                                                    {
                                                        productList.length < product_totalRecord ?
                                                            <Button type="primary" block onClick={loadMoreProduct}>加载更多</Button> :
                                                            <div style={{ width: '100%', textAlign: 'center', color: 'grey' }}>全部数据</div>
                                                    }
                                                </div>
                                            </div>
                                        )}>
                                        {productList.map((item, index) => {
                                            return (<Option key={index} value={item.productName}>{item.productName}</Option>)
                                        })}
                                    </Select>

                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label={'厂家：'}
                                    name={[name, 'factoryName']}
                                    fieldKey={[fieldKey, 'factoryName']}
                                    rules={[{ required: true, message: '请选择' }]}
                                >

                                    <Select
                                        style={{ width: '150px' }}
                                        onChange={onSelectFactory}
                                        disabled={props.type === 'edit'}
                                        dropdownRender={menu => (
                                            <div>
                                                <div style={{ padding: '4px 6px' }}>
                                                    <Input placeholder="搜索" onChange={onSearchFactory} allowClear />
                                                </div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, textAlign: 'center' }}>
                                                    {
                                                        factoryList.length < factory_totalRecord ?
                                                            <Button type="primary" block loading={loading} onClick={loadMoreFactory}>加载更多</Button> :
                                                            <div style={{ width: '100%', textAlign: 'center', color: 'grey' }}>全部数据</div>
                                                    }
                                                </div>
                                            </div>
                                        )}>
                                        {factoryList.map((item, index) => {
                                            return (<Option key={index} value={item.factoryName}>{item.factoryName}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label={'牌号：'}
                                    name={[name, 'brandNumber']}
                                    fieldKey={[fieldKey, 'brandNumber']}
                                    rules={[{ required: true, message: '请选择' }]}
                                >

                                    <Select
                                        style={{ width: '150px' }}
                                        onChange={onSelectBrand(key)}
                                        disabled={props.type === 'edit'}
                                        dropdownRender={menu => (
                                            <div>
                                                <div style={{ padding: '4px 6px' }}>
                                                    <Input placeholder="搜索" onChange={onSearchBrand} allowClear />
                                                </div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, textAlign: 'center' }}>
                                                    {
                                                        brandList.length < brand_totalRecord ?
                                                            <Button type="primary" block loading={loading} onClick={loadMoreBrand}>加载更多</Button> :
                                                            <div style={{ width: '100%', textAlign: 'center', color: 'grey' }}>全部数据</div>
                                                    }
                                                </div>
                                            </div>
                                        )}>
                                        {brandList.map((item, index) => {
                                            return (<Option key={index} value={item.brandNumber}>{item.brandNumber}</Option>)
                                        })}
                                    </Select>

                                </Form.Item>

                                <div>
                                    <Form.Item
                                        {...restField}
                                        label={`最新价（元/吨）：`}
                                        name={[name, 'price']}
                                        fieldKey={[fieldKey, 'price']}
                                        rules={[{ required: true, message: '请输入最新价' }]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={0} step={1} precision={0} placeholder="请输入最新价" />
                                    </Form.Item>
                                    {props.type != 'edit' &&
                                        <Form.Item
                                            {...restField}
                                            label={`市场价（元/吨）：`}
                                            name={[name, 'marketPrice']}
                                            fieldKey={[fieldKey, 'marketPrice']}
                                            style={{ paddingLeft: '12px' }}
                                        >   
                                            {
                                                form.getFieldValue('offerList')[key] && form.getFieldValue('offerList')[key].marketPrice ?
                                                <Statistic
                                                    precision={2}
                                                    valueStyle={{ color: '#cf1322', fontSize: '18px' }}
                                                /> : null
                                            }
                                        </Form.Item>
                                    }
                                </div>
                                <div>
                                    <Form.Item
                                        {...restField}
                                        label={'数量（吨）：'}
                                        name={[name, 'quantity']}
                                        fieldKey={[fieldKey, 'quantity']}
                                        rules={[{ required: true, message: '请输入数量' }]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={0} step={1} precision={0} placeholder="请输入数量" />
                                    </Form.Item>
                                    {props.type != 'edit' &&
                                        <Form.Item
                                            {...restField}
                                            label={`涨跌幅： `}
                                            name={[name, 'marketPriceFloats']}
                                            fieldKey={[fieldKey, 'marketPriceFloats']}
                                            style={{ paddingLeft: '12px' }}
                                        >
                                            {
                                                form.getFieldValue('offerList')[key] && (form.getFieldValue('offerList')[key].marketPriceFloats || form.getFieldValue('offerList')[key].marketPriceFloats === 0)  ? 
                                                <Statistic
                                                    precision={2}
                                                    valueStyle={{ color: '#cf1322', fontSize: '18px' }}
                                                    suffix="%"
                                                /> : null
                                            }
                                        </Form.Item>
                                    }
                                </div>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                ) : null}
                            </Space>
                        ))}
                        {props.type != 'edit' &&
                            <Form.Item>
                                <Button
                                    shape="circle"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                </Button>
                                <Col style={{'marginTop': 10}}>
                                <p style={{'color': 'red'}}>
                                    以达到上限不可再新增报价，您可以点击下方 <span style={{color: '#1890FF '}}>【一键生成】</span>按钮提交报价或前往 <span style={{color: '#1890FF '}}>【价格管理】</span>页面删除报价数量后再提交
                                </p>
                                </Col>
                            </Form.Item>
                        }
                    </>
                )}
            </Form.List>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    一键生成
                </Button>
            </Form.Item>
        </Form>
    );
}

export default AddOfferDlg