import {useReducer} from 'react';

const initState = {
    userInfo: undefined
}
function Reducer (){
    const [user, userDispatch] = useReducer((state, action)=>{
        switch(action.type){
            case 'SET_USER_INFO':
                state.userInfo = action.payload
                break;
            default:
                break;  
        }
        return { ...state }

    }, initState)
    return { user, userDispatch }
}

export default Reducer