import { useEffect, useContext } from 'react'
import { HashRouter as Router, Route, Switch, useHistory} from 'react-router-dom'
import Login from './login'
import PrivacyStatement from './doc/privacyStatement'
import UserAgreement from './doc/userAgreement'
import Header from '@/layout/header'
import SideBar from '@/layout/sideBar'
import Price from '@/page/price/index'
import Import from '@/page/import/list'
import { getUserInfo } from '@/api/user'
import { GlobalContext } from '@/store'
const Index = (prop) => {
    const context = useContext(GlobalContext)
    const { user, userDispatch } = context
    const history = useHistory()
    // 获取用户信息
    useEffect(() => {
        if (!user.userInfo) {
            const getInfo = async () => {
                let res = await getUserInfo()
                if(res) {
                    userDispatch({ type: 'SET_USER_INFO', payload: res})
                    history.push('/app/price')
                }
            }
            getInfo()
        }
    }, [user.userInfo])
    return <>
        <Router>
            <Route path="/login" exact component={Login}></Route>
            <Route path="/privacyStatement" exact component={PrivacyStatement}></Route>
            <Route path="/userAgreement" exact component={UserAgreement}></Route>
            <Route key="/app" path="/app">
                <div className="app-container">
                    <SideBar></SideBar>
                    <div className="app-main">
                        <Header></Header>
                        <div className="app-body">
                            <Router>
                                <Switch>
                                    <Route key={`/app/price`} path={`/app/price`} component={Price} exact></Route>
                                    <Route key={'/app/Import'} path={'/app/import'} component={Import} exact></Route>
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </div>
            </Route>
        </Router>
    </>
}
export default Index
