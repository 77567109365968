import http from '@/http'

// 获取用户信息
export const getUserInfo = (params) => {
    return http({
        url: '/api/user/info',
        method: 'get',
        params
    })
}

// 更新用户信息
export const updateUserInfo = (data) => {
    return http({
        url: '/api/user/info/update',
        method: 'post',
        data
    })
}