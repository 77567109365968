import React, { useContext, useEffect, useRef, useState } from 'react'
import { HashRouter as Router, Route, Switch, useHistory} from 'react-router-dom'
import { GlobalContext } from '@/store'
import { getOfferList, getCountOffer, getProductList, renewOfferList, deleteOffer } from '@/api/price'
import Table from '@/components/table'
import { Row, Col, Space, Form, Input, Button, Upload, Popconfirm, Modal, DatePicker, message, notification } from 'antd'
import dayjs from 'dayjs'
import AddOfferDlg from './addOfferDlg'
import UserInfoModal from '@/page/modal/userInfo'

const Price = () => {
    const tableRef = useRef()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const context = useContext(GlobalContext)
    const { price, priceDispatch } = context
    const { user, userDispatch } = context
    const { userInfo } = user
    const { productList, productListTotal, pageNo, pageSize } = price
    const [loading, setLoading] = useState(false)
    const [currentOffer, setCurrentOffer] = useState({})
    const [operateType,setOperateType] = useState('')
    const [totalQuotation, setTotalQuotation] = useState(0)
    const history = useHistory()
    const [userInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [isComplete, setIsComplete] = useState(false); // 企业信息是否完善
    const [maxQuotation, setMaxQuotation] = useState(100);

    useEffect(() => {
        if (userInfo) {
            checkCorporateInfo();
            setMaxQuotation(userInfo.authenticationStatus === 1 ? 200 : 100)
        }
    }, [user.userInfo]);

    useEffect(() => {
        if (isComplete) {
            inquireTotalQuotation();
        }
    }, [isComplete])

    // 判断企业信息每一栏是否为空
    const checkCorporateInfo = async () => {
        // console.log("userInfo123 = ", userInfo)
        const keys = ["companyName", "contact", "contactMobile", "contactAddress"];
        const arr = Object.entries(userInfo).filter(([key, value]) => keys.includes(key) && value);
        return new Promise((resolve, reject) => {
            if (arr.length === keys.length) {
                setIsComplete(true);
                return resolve("企业信息已完善", userInfo);
            }
            setUserInfoModalVisible(true);
            setIsComplete(false);
            return reject("企业信息未完善");
        });
    }

    const onGetProductList = async (params) => {
        setLoading(true)
        let res = await getOfferList(params)
        priceDispatch({ type: 'SET_PRODUCT_LIST', payload: { ...res } })
        await inquireTotalQuotation()
        setLoading(false)
    }

    const inquireTotalQuotation = async () => {
        const total = await getCountOffer()
        setTotalQuotation(total)
        if (total >= maxQuotation && isComplete) {
            Modal.confirm({
                icon: null,
                title: getModalTitle(1),
                content: getModalContet(1),
                cancelText: '取消',
                okText: '确定'
            })
        }
    }

    const getModalTitle = (item) => {
        const style = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#1D2129',
            fontSize: '16px',
            fontWeight: 500
        }
        return <div style={style}>
            <img style={{width: 20, height: 20, marginRight: 10, }} src={item === 1 ? "/img/title-icon-1.png" : '/img/title-icon-2.png' } alt="" />
            {item === 1 ? '标题' : '批量删除'}
        </div>
    }

    const getModalContet = (item) => {
        const style = {
            color: '#1D2129',
            fontSize: '14px',
            fontWeight: 400,
            marginTop: '25px',
            textAlign: 'center'
        }
        return item === 1 ?
                <div style={style}>
                    平台为每家公司免费提供 <span style={{color: '#1890FF '}}>{{maxQuotation}}</span> 条报价，注意总报价数超过上限后不可再新增报价，您可以删减历史报价后再提交
                </div>
                :
                <div style={style}>
                    是否确认批量删除选中的报价？
                </div>
    }

    const deleteOfferItem = async (params) => {
        await deleteOffer({ ids: params })
        message.success('删除成功')
        onGetProductList({ pageNo, pageSize })
    }

    // 编辑报价
    const editOfferItem = (item) => {
        setIsModalVisible(true)
        setCurrentOffer(item)
        setOperateType('edit')
    }

    const columns = [
        {
            title: '品名',
            dataIndex: 'productName',
            key: 'productName',
            align: 'center'
        },
        {
            title: '厂家',
            dataIndex: 'factoryName',
            key: 'factoryName',
            align: 'center'
        },
        {
            title: '牌号',
            dataIndex: 'brandName',
            key: 'brandName',
            align: 'center'
        },
        {
            title: '报价（元/吨）',
            dataIndex: 'price',
            key: 'price',
            align: 'center'
        },
        {
            title: '数量（吨）',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: '报价时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            align: 'center',
            render: (text) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'opertation',
            align: 'center',
            render: (_, record) => {
                return (
                    <>
                        <Button style={{ padding: '0 10px' }} type="link" onClick={() => { editOfferItem(record) }}>编辑</Button>
                        <span style={{ color: '#eee' }}>|</span>
                        <Popconfirm title="确定此次操作？" onConfirm={() => { deleteOfferItem(record.id) }} okText="确定" cancelText="取消">
                            <Button style={{ padding: '0 10px' }} type="link">删除</Button>
                        </Popconfirm>
                    </>
                )
            }
        }
    ]
    const fields = [
        <Col span={8} key={'productName'} >
            <Form.Item name={`productName`} label={`品名`}>
                    <Input placeholder="请输入品名" />
            </Form.Item>
        </Col>,
        <Col span={8} key={'factoryName'}>
            <Form.Item name={`factoryName`} label={`厂家`}>
                <Input placeholder="请输入厂家" />
            </Form.Item>
        </Col>,
        <Col span={8} key={'brandName'}>
            <Form.Item name={`brandName`} label={`牌号`}>
                <Input placeholder="请输入牌号" />
            </Form.Item>
        </Col>,
        <Col span={8} key={'date'}>
            <Form.Item name={`startTime,endTime`} label={`报价时间`}>
                <DatePicker.RangePicker placeholder={['开始时间', '结束时间']} />
            </Form.Item>
        </Col>
    ]

    // 一键更新报价
    const confirm = async () => {
        await checkCorporateInfo();
        renewOfferList({ pageNo, pageSize })
        message.success('更新报价成功')
        onGetProductList({ pageNo, pageSize })
    }

    const batchDeletion = () => {
        Modal.confirm({
            icon: null,
            title: getModalTitle(2),
            content: getModalContet(2),
            cancelText: '取消',
            okText: '确定',
            onOk: async () => {
                const item = tableRef.current.getSelectedRows()
                if (!item.length) {
                    message.error('请选择要删除的数据')
                    return
                }
                const param = item.map(el => el.id)
                setLoading(true)
                await deleteOfferItem(param.toString())
                setLoading(false)
                tableRef.current.clearSelectedRows()
            }
        })
    }

    // 打开新增报价弹窗
    const openAddQuotationDlg = async () => {
        await checkCorporateInfo();
        setIsModalVisible(true)
        setOperateType('add')
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // excel导入
    const importExcel = async (info) => {
        await checkCorporateInfo();
        setLoading(true)
        const res = info?.file?.response
        if (res && res.retCode) {
            if (res.succ) {
                const {failList, addCount, updateCount, failCount, totalCount } = res.result
                if (failList && failList.length) {
                    if (addCount === 0 && updateCount === 0) {
                        notification.error({description: '导入失败', duration: 2})
                    }
                    setTimeout(() => {
                        setLoading(false)
                        sessionStorage.setItem('IMPORT_RESULT', JSON.stringify(res.result))
                        history.push('/app/import')
                    }, 2000);
                } else {
                    notification.success({description: `共收到${totalCount}条, 全部导入成功`, duration: 2})
                    setTimeout(() => {
                        setLoading(false)
                        onGetProductList({ pageNo, pageSize })
                    }, 2000);

                }
            } else {
            message.warning(res.msg)
            }
        }
    }

    const downloadTemplate = () => {
      const iframe = document.createElement("iframe")
      iframe.style.display = "none"
      iframe.style.height = "0"
      iframe.src = "/api/offer/down/excelOfferXls"
      document.body.appendChild(iframe)
      setTimeout(() => {
        iframe.remove()
      }, 5 * 10 * 1000)
    }

    const extraBtn = [
      <Space size={15}>
        <Button type="primary" disabled={totalQuotation >= maxQuotation} onClick={openAddQuotationDlg}>
          新增报价
        </Button>
        <Upload
          multiple={false}
          name="file"
          action="/api/offer/import" // todo: action
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          method="post"
          showUploadList={false}
          onChange={importExcel}
        >
          <Button type="primary" disabled={totalQuotation >= maxQuotation}>excel导入</Button>
        </Upload>
        <Button type="primary" onClick={downloadTemplate}>
          下载模板
        </Button>
        <Popconfirm
          title="确定要更新当前报价吗？"
          onConfirm={confirm}
          okText="确定"
          cancelText="取消"
          disabled={totalQuotation >= maxQuotation}
        >
          <Button type="primary" disabled={totalQuotation >= maxQuotation}>一键更新报价</Button>
        </Popconfirm>
        <Button type="primary" onClick={batchDeletion}>批量删除</Button>
      </Space>,
      <UserInfoModal visible={userInfoModalVisible} setVisible={setUserInfoModalVisible} />
    ]

    const TableTitle = () => {
        return [
            totalQuotation <= maxQuotation ?
             <Space style={{fontSize: '14px', color: '#000000'}}>该公司已有报价<span style={{color: '#1890FF'}}>{totalQuotation} </span>条, 还可报价 <span style={{color: '#1890FF'}}>{maxQuotation - totalQuotation}</span> 条</Space>
             :
             <Space style={{fontSize: '14px', color: '#000000'}}>该公司已有报价 <span style={{color: 'red'}}>{totalQuotation}</span> 条,<span style={{color: 'red'}}>超出上限</span> </Space>
        ]
    }

    return (
        <>
            <Table
                rowKey={'id'}
                ref={tableRef}
                title={TableTitle()}
                rowSelection
                loading={loading}
                onGetData={onGetProductList}
                fields={fields}
                columns={columns}
                dataSource={productList}
                total={productListTotal}
                extraBtn={extraBtn}
            ></Table>
            <Modal title={`${operateType === 'add' ? '新增': '编辑'}报价`}
                width={1000}
                visible={isModalVisible}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={null}
                afterClose={() => { onGetProductList({ pageNo, pageSize }) }}
            >
                <AddOfferDlg totalQuotation={totalQuotation} closeDlg={() => { setIsModalVisible(false) }} currentOffer={currentOffer} type={operateType} />
            </Modal>
        </>
    )
}
export default Price
