import "./doc.less"

const PrivacyStatement = (prop) => {
	return <div className="doc">
		<div className="doc-title">二级节点业务系统 用户协议</div>
		<div className="doc-subtitle">用户协议</div>
		<div className="doc-content">
			<div className="indentation">感谢您选择二级节点业务系统服务（以下简称“本服务”）。二级节点业务系统用户服务协议（以下简称“本协议”）由二级节点（以下简称“我们”）和您签订。为您提供标识注册、标识查询、数据统计分析、系统管理等多种功能的应用。您有权选择不使用本服务；如果您选择使用本服务，将视为您同意接受本协议条款及规则的约束。</div>
			<div className="indentation bold">一、二级节点业务系统用户服务协议的确认</div>
			<div className="indentation">1、本协议有助于您了解二级节点业务系统为您提供的服务内容及您使用服务的权力和义务，请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。您可联系我们，以便我们为您解释和说明。您通过页面点击或其他方式确认即表示您已同意本协议。</div>
			<div className="indentation">2、如我们对本协议进行修改，若您无法同意变更修改后的协议内容，您有权停止使用相关服务；双方协商一致的，也可另行变更相关服务和对应协议内容。</div>
			<div className="indentation">3、如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人，并在您监护人的指导下阅读本协议和使用我们的服务。若您非中华人民共和国境内（为本协议之目的，不包括香港、澳门特别行政区及台湾地区）用户，您还需同时遵守您所属国家或地区的法律，且您确认，订立并履行本协议不违反您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规。</div>
			<div className="indentation bold">二、二级节点业务系统为您提供的服务内容</div>
			<div className="indentation">二级节点业务系统为您提供工业互联网标识注册、解析的相关服务，并对数据进行统计分析，提供多维度的数据展示。</div>
			<div className="indentation bold">三、账户的注册和使用</div>
			<div className="indentation">（一）注册</div>
			<div className="indentation">1、您可通过密码注册取得账户后使用二级节点业务系统服务。具体验证方式以二级节点业务系统页面提示为准。</div>
			<div className="indentation">2、您在账户中设置的昵称、留言等请务必遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，否则我们可能会取消您的昵称、留言。</div>
			<div className="indentation">（二）使用</div>
			<div className="indentation">身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作、发出的指令视为您本人做出。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的风险和损失需要由您自行承担。 您同意：</div>
			<div className="indentation">
				1、为了保障您的资金安全，请把手机及其他设备的密码设置成与用户标识及账户的密码不一致。如您发现有他人冒用或盗用您的用户标识、账户或者二级节点业务系统登录名及密码，或您的手机或其他有关设备丢失时，请您立即以有效方式通知我们；您还可以向我们申请暂停或停止二级节点业务系统服务。您在持续登录二级节点业务系统平台时段结束时，请以正确步骤退出应用。由于我们对您的请求采取行动需要合理时间，如我们未在合理时间内采取有效措施，导致您损失扩大的，我们将就扩大的损失部分承担责任，但我们对采取行动之前已执行的指令免于承担责任。
			</div>
			<div className="indentation">2、用户标识和账户仅限您本人使用，请勿转让、借用、赠与、继承，但二级节点业务系统账户内的相关财产权益可被依法继承。</div>
			<div className="indentation">3、基于运行和交易安全的需要，我们可能会暂停或者限制二级节点业务系统服务部分功能，或增加新的功能。</div>
			<div className="indentation">4、为了维护良好的网络环境，我们有时需要了解您使用二级节点业务系统服务的真实背景及目的，如我们要求您提供相关信息或资料的，请您配合提供。</div>
			<div className="indentation bold">四、使用二级节点业务系统服务的注意事项</div>
			<div className="indentation">为有效保障您使用我们服务时的合法权益，我们提醒您注意以下事项：</div>
			<div className="indentation">（一）身份验证</div>
			<div className="indentation">1、您在注册、使用我们服务的过程中，请您提供合法、真实、有效、准确并完整的资料（根据具体验证需要，可能包括单位名称、企业类型、机构证件类型、证件号、营业执照、企业/机构行业、注册地、联系人等）。 为了能将账户资金变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知我们。
				为了及时有效地验证您的信息（包括但不限于身份信息、账户信息等），根据法律法规及监管规定或我们认为有需要时，您同意我们可以把您的信息提供给第三方，也同意第三方可以把您的信息提供给我们，以便我们进行验证。您应确保二级节点业务系统登录名、账户绑定的手机号均为您本人持有，如您占用了他人的手机号，为避免给手机号持有人带来不便或不利影响，也为了您的资金安全，我们可能将该手机号从您的账户中删除并解除关联。
			</div>
			<div className="indentation">2、为了满足相关监管规定的要求，请您按照我们要求的时间提供您的身份信息以完成身份验证，否则您可能无法进行收款、提现、余额支付、购买理财产品等操作，且我们可能对您的账户余额进行止付或注销您的账户。</div>
			<div className="indentation">（二）存在如下情形时，我们可能会对您名下二级节点业务系统账户暂停或终止提供二级节点业务系统服务，且可能限制您所使用的产品或服务功能（比如对这些账户名下的资产和在途交易采取止付、取消交易等限制措施，或对交易的方式、规模、频率等进行限制）：</div>
			<div className="indentation">（1）您违反了法律法规的规定或本协议的约定；</div>
			<div className="indentation">（2）根据相关法律法规或有权机关的要求；</div>
			<div className="indentation">（3）您的账户可能产生风险的；</div>
			<div className="indentation">（4）您遭到他人投诉，且对方已经提供了一定证据的；</div>
			<div className="indentation">（5）您可能错误地操作他人账户，或者将他人账户进行了身份验证的。</div>
			<div className="indentation">除（1）、（2）、（3）规定的情形外，如您申请恢复服务、解除上述止付或限制，请您向我们提供相关资料及身份证明等文件，以便我们进行核实。</div>
			<div className="indentation">（三）二级节点业务系统服务规则</div>
			<div className="indentation">1、您认可账户的使用记录、交易数据等均以我们的系统记录为准。如您对该等数据存有异议的，可及时向我们提出，我们会根据您提供的相关证据积极予以核实。</div>
			<div className="indentation">2、对于您提供及发布的文字等非个人隐私信息的知识产权归您或相关权利人所有，为向所有用户提供更优质的服务，我们及关联公司可能需要使用您的非个人隐私信息或授权给其他第三方使用。在此，您理解并同意我们及关联公司可以存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人隐私信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其他作品内或授权给其他第三方使用。</div>
			<div className="indentation">3、为了更好地向您提供服务，请您在使用二级节点业务系统服务过程中，遵守本协议内容、二级节点业务系统平台上出现的关于操作的提示及我们发送到您手机的提示。</div>
			<div className="indentation">4、我们会以发送短信、电话或客户端通知等方式向您发送通知，例如提示您进行相关操作，请您及时予以关注。</div>
			<div className="indentation">5、服务咨询与投诉</div>
			<div className="indentation">在使用我们服务的过程中，如遇到问题，您可以通过在线客服等方式联系我们。为了保护您及他人的合法权益，维护绿色网络环境，如您被他人投诉或投诉他人，我们可能会将您的姓名、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷；同时，出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的情况下，我们可能会将您的姓名及身份证号码、联系方式、投诉相关内容提供给争议相对方。但法律法规明确禁止提供的除外。</div>
			<div className="indentation">（四）我们的承诺和声明</div>
			<div className="indentation">1、基于相关法律法规的规定，对本协议项下的服务，我们均无法提供担保、垫资。</div>
			<div className="indentation bold">五、用户合法使用二级节点业务系统服务的承诺</div>
			<div className="indentation">1、您需要遵守中华人民共和国相关法律法规及您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规，不得将我们的服务用于非法目的（包括用于禁止或限制交易物品的交易），也不得以非法方式使用我们的服务。</div>
			<div className="indentation">2、您不得利用我们的服务从事侵害他人合法权益之行为或违反国家法律法规，否则我们有权进行调查、延迟或拒绝结算或停止提供服务，且您需要自行承担相关法律责任，如因此导致我们或其他方受损的，您需要承担赔偿责任。</div>
			<div className="indentation">3、上述1和2适用的情况包括但不限于：</div>
			<div className="indentation">（1）侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益；</div>
			<div className="indentation">（2）违反保密义务；</div>
			<div className="indentation">（3）冒用他人名义使用我们的服务；</div>
			<div className="indentation">（4）从事不法交易行为，如洗钱、恐怖融资、赌博、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他我们认为不得使用我们的服务进行交易的物品等；</div>
			<div className="indentation">（5）提供赌博资讯或以任何方式引诱他人参与赌博；</div>
			<div className="indentation">（6）未经授权使用他人银行卡，或利用信用卡、花呗套取现金；</div>
			<div className="indentation">（7）进行与您或交易对方宣称的交易内容不符的交易，或不真实的交易；</div>
			<div className="indentation">（8）从事可能侵害我们的服务系统、数据的行为。</div>
			<div className="indentation">4、账户仅限本人/本企业使用，您需充分了解并清楚知晓出租、出借、出售、购买账户的相关法律责任和惩戒措施，承诺依法依规开立和使用本人账户。</div>
			<div className="indentation">5、您理解，我们的服务有赖于系统的准确运行及操作。若出现系统差错、故障、您或我们不当获利等情形，您同意我们可以采取更正差错、扣划款项等适当纠正措施。</div>
			<div className="indentation">6、您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、客户端至服务器端的数据、服务器数据）进行复制、修改、编译、整合或篡改，不得修改或增减我们系统的功能。</div>
			<div className="indentation bold">六、用户权益保障及信息保护</div>
			<div className="indentation">1、客户权益保障承诺</div>
			<div className="indentation">我们一直秉承“客户第一”的原则，努力为您带来微小而美好的改变，保障您在使用服务时的合法权益。</div>
			<div className="indentation">2、隐私权保护</div>
			<div className="indentation">我们重视对您信息的保护。关于您的信息依《二级节点业务系统 隐私声明》受到保护与规范，详情请参阅《二级节点业务系统 隐私声明》。</div>
			<div className="indentation bold">七、不可抗力、免责及责任限制</div>
			<div className="indentation">（一）免责条款</div>
			<div className="indentation">因下列原因导致我们无法正常提供服务，我们免于承担责任：</div>
			<div className="indentation">1、我们的系统停机维护或升级；</div>
			<div className="indentation">2、因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</div>
			<div className="indentation">3、您的电脑软硬件和通信线路、供电线路出现故障的；</div>
			<div className="indentation">4、因您操作不当或通过非经我们授权或认可的方式使用我们服务的；</div>
			<div className="indentation">5、因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</div>
			<div className="indentation">尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。</div>
			<div className="indentation">（二）责任限制</div>
			<div className="indentation">我们可能同时为您及您的（交易）对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张我们在提供服务时存在法律上的瑕疵。</div>
			<div className="indentation bold">八、知识产权的保护</div>
			<div className="indentation">1、我们及关联公司的系统及二级节点业务系统平台上的内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由我们或关联公司依法拥有知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</div>
			<div className="indentation">2、非经我们或关联公司书面同意，请勿擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表二级节点业务系统网站程序或内容。</div>
			<div className="indentation">3、尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任。</div>
			<div className="indentation bold">九、法律适用与管辖</div>
			<div className="indentation">本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。</div>
		</div>
	</div>

}
export default PrivacyStatement
