import './App.less';
import Index from './page/index'
import { HashRouter as Router, Route} from 'react-router-dom'

function App() {
  return (
    <div style={{width: '100%', height: '100%'}}>
      <Router>
        <Route key="/" path="/" component={Index} />
      </Router>
    </div>
  );
}

export default App;
