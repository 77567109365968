import React, { useContext } from 'react'
import {Space, Button} from 'antd'
import { MenuUnfoldOutlined , MenuFoldOutlined } from '@ant-design/icons'
import {GlobalContext} from '@/store'
import UserInfo from './userInfo'
import "./header.less"

const Header = (prop) => {
    const { app, appDispatch, user } = useContext(GlobalContext)
    const { collapsed } = app
    const { userInfo } = user
    return (
        <div className="app-header">
            <div className="header">
                <Space class="left">
                    <Button type="text" icon={collapsed ? <MenuUnfoldOutlined />:<MenuFoldOutlined />}  onClick={() => {appDispatch({type: 'CHANGE_COLLAPSED', payload: !collapsed})}} />
                </Space>
                <div className="share">
                    <img className="hot" src="/img/hot.png"/>
                    <span>完成企业认证，领30天会员，赚积分，报价条数翻倍</span>
                    <img className="arrow" src="/img/arrow.png"/>
                    <div className="share-toast">
                        <div className="title">微信扫码进行认证</div>
                        <div className="qr">
                            <img className="code" src="/img/code.png"/>
                        </div>
                        <div className="subtitle">塑料行情报价</div>
                    </div>
                </div>
                <UserInfo userInfo={userInfo}></UserInfo>
            </div>
        </div>
    )
}
export default Header
