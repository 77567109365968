import React, { forwardRef, useImperativeHandle } from 'react';
import { Form, Row, Col, Button } from 'antd';
import _ from 'lodash'
import './SearchBar.css'

const SearchBar = (props, ref) => {
  useImperativeHandle(ref, () => ({
    getFieldsValue: () => {
      return getFieldsValue()
    }
  }))

  const [form] = Form.useForm();
  const {fields, handleSubmit} = props

  const getFieldsValue = () => {
    // 格式化数据,如:moment,array等
    let temp = {...form.getFieldsValue()}
    Object.keys(temp).map(key => {
      // 把时间数据转换成字符串
      if(temp[key] && temp[key]._isAMomentObject){
        temp[key] = temp[key].format('YYYY-MM-DD')
      }
      // 把时间区间数据转换成两个字符串
      if(key.indexOf(',') !== -1 && Array.isArray(temp[key])){
        let keyArr = key.split(',')
        keyArr.map((v, index) => {
          temp[v] = temp[key][index]._isAMomentObject ? temp[key][index].format('YYYY-MM-DD') : temp[key][index]
        })
        temp = _.omit(temp, [key])
      }
    })
    return temp
  }

  const resetFieldsValue= () => {
    form.resetFields()
    handleSubmit()
  }

  const renderFields = () => {
    switch (fields.length % 3) {
      case 0: 
        return (
          <div>
            <Row gutter={24}>{fields}</Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button className='btn-reset' onClick={() => { resetFieldsValue() }}>重置</Button>
                <Button className='btn-inquire' style={{ marginLeft: '8px' }} type="primary" htmlType="submit">查询</Button>
              </Col>
            </Row>
          </div>
        )
      case 1: 
        return (
          <Row gutter={24}>
            {/* {[...fields, <Col span={8} style={{ textAlign: 'left' }}> */}
            {[...fields, <Col span={16} style={{ textAlign: 'right' }}>
            <Button className='btn-reset' onClick={() => { resetFieldsValue() }}>重置</Button>
              <Button className='btn-inquire' style={{ marginLeft: '8px' }} type="primary" htmlType="submit">查询</Button>
          </Col>]}
        </Row>
      )
      case 2: 
        return (
          <Row gutter={24}>
            {/* {[...fields, <Col span={8} style={{ textAlign: 'center' }}> */}
            {[...fields, <Col span={8} style={{ textAlign: 'right' }}>
            <Button className='btn-reset' onClick={() => { resetFieldsValue() }}>重置</Button>
              <Button className='btn-inquire' style={{ marginLeft: '8px' }} type="primary" htmlType="submit">查询</Button>
          </Col>]}
        </Row>
      )
    }
  }

  return (
    <Form form={form} name="search_bar" onFinish={handleSubmit} style={{padding: '36px 40px 11px', background: '#fff'}} >
      {fields ? renderFields() : ''}
    </Form>
  );
};

export default forwardRef(SearchBar)